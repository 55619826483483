import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import { cn } from "../../lib/utils";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "chatbot-fixed chatbot-inset-0 chatbot-z-50 chatbot-bg-black/80  data-[state=open]:chatbot-animate-in data-[state=closed]:chatbot-animate-out data-[state=closed]:chatbot-fade-out-0 data-[state=open]:chatbot-fade-in-0",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    fullHeight?: boolean;
    isRtl?: boolean;
  }
>(({ className, children, fullHeight, isRtl, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      dir={isRtl ? "rtl" : undefined}
      ref={ref}
      className={cn(
        "chatbot-fixed chatbot-top-8 chatbot-bottom-8 sm:chatbot-bottom-32 lg:chatbot-w-[600px] chatbot-max-w-[600px] chatbot-z-50  chatbot-gap-4 chatbot-bg-background chatbot-p-6 chatbot-shadow-lg chatbot-duration-200 data-[state=open]:chatbot-animate-in data-[state=closed]:chatbot-animate-out data-[state=closed]:chatbot-fade-out-0 data-[state=open]:chatbot-fade-in-0 data-[state=closed]:chatbot-zoom-out-95 data-[state=open]:chatbot-zoom-in-95 ata-[state=closed]:chatbot-slide-out-to-bottom-[48%] data-[state=open]:chatbot-slide-in-from-bottom-[48%] chatbot-rounded-lg",
        !fullHeight && "chatbot-top-auto lg:chatbot-h-[75vh] chatbot-min-h-[60vh]",
        isRtl
          ? "chatbot-left-8 chatbot-right-8 sm:chatbot-right-auto data-[state=closed]:chatbot-slide-out-to-left-1/2 data-[state=open]:chatbot-slide-in-from-left-1/2 "
          : "chatbot-right-8 chatbot-left-8 sm:chatbot-left-auto data-[state=closed]:chatbot-slide-out-to-right-1/2 data-[state=open]:chatbot-slide-in-from-right-1/2 ",
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close
        className={cn(
          "chatbot-absolute chatbot-top-6 chatbot-rounded-sm chatbot-opacity-70 chatbot-ring-offset-background chatbot-transition-opacity hover:chatbot-opacity-100 focus:chatbot-outline-none focus:chatbot-ring-2 focus:chatbot-ring-ring focus:chatbot-ring-offset-2 disabled:chatbot-pointer-events-none data-[state=open]:chatbot-bg-accent data-[state=open]:chatbot-text-muted-foreground",
          isRtl ? "chatbot-left-6" : "chatbot-right-6"
        )}
      >
        <Cross2Icon className="chatbot-h-4 chatbot-w-4" />
        <span className="chatbot-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "chatbot-flex chatbot-flex-col chatbot-space-y-1.5 chatbot-text-center",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "chatbot-flex chatbot-flex-col-reverse sm:chatbot-flex-row sm:chatbot-justify-end sm:chatbot-space-x-2",
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "chatbot-text-lg chatbot-leading-none chatbot-tracking-tight",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("chatbot-text-sm chatbot-text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
